import React from "react";
import Helmet from "react-helmet";

class Auth extends React.Component {
  render() {
    return (
      <div>
        <li class="nav-item">
          <a
            class="btn btn-success"
            href="#"
            data-toggle="modal"
            data-target="#AuthModal"
          >
            Войти
          </a>
        </li>
        <div
          class="modal fade"
          id="AuthModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="AuthModalLabel">
                  Авторизация
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                method="post"
                action="https://billing.ourvds.ru/billing.php"
              >
                <div class="modal-body">
                  <table
                    class="table table-bordered table-mini table-center"
                    align="center"
                  >
                    <tr class="table-warning" align="center">
                      <td>
                        <Helmet>
                          <script src="https://ulogin.ru/js/ulogin.js"></script>
                        </Helmet>
                        <a
                          href="#"
                          id="uLoginRtpl"
                          data-ulogin="display=window;fields=first_name,last_name,email;optional=phone,city,country;redirect_uri=https%3A%2F%2Fbilling.ourvds.ru%2Fbilling.php"
                        >
                          <img src="https://ulogin.ru/img/button.png" />
                        </a>
                      </td>
                    </tr>
                  </table>

                  <table
                    class="table table-mini table-bordered table-center"
                    align="center"
                  >
                    <tr>
                      <td>Ваш логин или email:</td>
                      <td>
                        <input class="form-control" type="text" name="login" />
                      </td>
                    </tr>
                    <tr>
                      <td>Ваш пароль:</td>
                      <td>
                        <input
                          class="form-control"
                          type="password"
                          name="pass"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Защитный код:</td>
                      <td>
                        <center>
                          <img
                            name="captcha"
                            class="img-rounded"
                            src="https://billing.ourvds.ru/billing.php?do=captcha"
                          />
                        </center>
                        <hr />
                        <input
                          class="form-control"
                          type="text"
                          name="captchakeyin"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="modal-footer">
                  <a
                    class="btn btn-warning"
                    href="https://billing.ourvds.ru/billing.php?do=forgotpass"
                  >
                    Забыли пароль?
                  </a>
                  <input
                    class="btn btn-success"
                    type="submit"
                    value="Войти в биллинг"
                    name="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Auth;
