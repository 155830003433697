import React from "react";
import Select from "react-select";

const cost = [
  { value: "rubles", label: "₽ Рубли" },
  { value: "dollars", label: "$ Доллары" },
  { value: "euros", label: "€ Евро" }
];

const locations = [
  { value: "ru", label: "🇷🇺 Россия" },
  { value: "nl", label: "🇳🇱 Нидерланды" },
  { value: "lt", label: "🇱🇹 Литва" }
];

class Search extends React.Component {
  render() {
    return (
      <div>
        <hr />
        <div id="filter-panel" class="collapse filter-panel">
          <div class="card">
            <div class="card-body">
              <form role="form">
                <div class="row">
                  <div class="col">
                    <label class="filter-col" for="pref-orderby">
                      Валюта:
                    </label>
                    <Select options={cost} />
                  </div>
                  <div class="col">
                    <div class="col">
                      <label class="filter-col" for="pref-orderby">
                        Локация:
                      </label>
                      <Select options={locations} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br />
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="collapse"
          data-target="#filter-panel"
        >
          <span class="glyphicon glyphicon-cog"></span> Фильтр
        </button>
        <hr />
      </div>
    );
  }
}
export default Search;
