import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "jquery";
import "bootstrap/dist/js/bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "./App.css";
import Helmet from "react-helmet";

import IndexPage from "./Pages/Index";
import VServerPage from "./Pages/Prices/VServer";
import DedicatedPage from "./Pages/Prices/Dedicated";
import DomainsPage from "./Pages/Prices/Domains";
import SSLPage from "./Pages/Addons/SSL";
import SoftwarePage from "./Pages/Addons/Software";
import RootpanelPage from "./Pages/Addons/Rootpanel";
import NotFound from "./Pages/NotFound";

import AuthModal from "./Auth";

function App() {
  return (
    <div>
      <Helmet>
        <script src="//code.jivosite.com/widget/Y7atpVdUDp" async></script>
      </Helmet>
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded">
        <Link class="navbar-brand" to="/">
          <img
            src="https://ourvds.ru/logo/logo.png"
            width="50"
            height="30"
            class="d-inline-block align-top"
            alt=""
          />
          OurVDS
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Услуги
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link class="dropdown-item" to="/price/vserver">
                  VPS/VDS
                </Link>
                <Link class="dropdown-item" to="/price/dedicated">
                  Выделенные сервера
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/price/domains">
                  Доменные имена
                </Link>
                <Link class="dropdown-item" to="/addons/ssl">
                  Сертификаты SSL
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/addons/software">
                  Лицензии ПО
                </Link>
                <Link class="dropdown-item" to="/addons/rootpanel">
                  Лицензии RootPanel
                </Link>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <AuthModal />
          </ul>
        </div>
      </nav>

      <div className="container">
        <Switch>
          <Route exact path={["/"]} component={IndexPage} />
          <Route path="/price/vserver" component={VServerPage} />
          <Route path="/price/dedicated" component={DedicatedPage} />
          <Route path="/price/domains" component={DomainsPage} />
          <Route path="/addons/ssl" component={SSLPage} />
          <Route path="/addons/software" component={SoftwarePage} />
          <Route path="/addons/rootpanel" component={RootpanelPage} />
          <Route component={NotFound} />
        </Switch>
      </div>

      <div className="container">
        <footer class="pt-4 my-md-5 pt-md-5 border-top">
          <div class="row">
            <div class="col-12 col-md">
              <img
                class="mb-2"
                src="https://ourvds.ru/logo/logo.png"
                alt=""
                width="40"
                height="24"
              />
              OurVDS
              <small class="d-block mb-3 text-muted">&copy; 2012-2021</small>
            </div>
            <div class="col-6 col-md">
              <h5>Услуги</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <Link class="text-muted" to="/price/vserver">
                    VPS/VDS
                  </Link>
                </li>
                <li>
                  <Link class="text-muted" to="/price/dedicated">
                    Выделенные сервера
                  </Link>
                </li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <h5>Дополнительно</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <Link class="text-muted" to="/price/domains">
                    Доменные имена
                  </Link>
                </li>
                <li>
                  <Link class="text-muted" to="/addons/ssl">
                    SSL Сертификаты
                  </Link>
                </li>
                <li>
                  <Link class="text-muted" to="/addons/software">
                    Лицензии ПО
                  </Link>
                </li>
                <li>
                  <Link class="text-muted" to="/addons/rootpanel">
                    Лицензии RootPanel
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
