import React from "react";
import axios from "axios";
const API_URL = "https://billing.ourvds.ru";

class DomainsPage extends React.Component {
  state = {
    domains: []
  };
  componentDidMount() {
    const url = `${API_URL}/apiv1/domains`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ domains: data });
        console.log(this.state.domains);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { error } = this.state;
    return (
      <div>
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Доменные имена</h1>
          <p class="lead">
            Настало время дать названию проекту, и войти в историю Интернета ;)
          </p>
        </div>
        <div class="container">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Зона</th>
                  <th scope="col">Privacy Person</th>
                  <th scope="col">Цена</th>
                  <th scope="col">Продление</th>
                  <th scope="col">Действие</th>
                </tr>
              </thead>
              <tbody>
                {this.state.domains.map((dm) => (
                  <tr>
                    <th>.{dm.zone}</th>
                    <td>{dm.privacy}</td>
                    <td>
                      {dm.cost_if_host}
                      {dm.cost_if_hostCurrency}
                    </td>
                    <td>
                      {dm.renew_if_host}
                      {dm.renew_if_hostCurrency}
                    </td>
                    <td>
                      <a
                        class="btn btn-success"
                        href={`https://billing.ourvds.ru/billing.php?do=order&type=d&zone=${dm.zone}`}
                      >
                        Заказать
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Почему низкие цены?
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                Мы сотрудничаем со многими регистраторы, для удобства подбора в
                плане цены ;)
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Как проверить домен, свободен он?
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                Достаточно при заказе выбрать зону, предварительно при заказе
                можно проверить посредством WHOIS.
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTree"
                    aria-expanded="false"
                    aria-controls="collapseTree"
                  >
                    Как назначить NS, и время их применения в силу?
                  </button>
                </h5>
              </div>
              <div
                id="collapseTree"
                class="collapse"
                aria-labelledby="headingTree"
                data-parent="#accordion"
              >
                <div class="card-body">
                  При выборе зоны и назначения имени, вы назначаете записи, и
                  ожидаете от 1 часа до 3е суток, с обновлением корневых DNS.
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    );
  }
}
export default DomainsPage;
