import React from "react";
import axios from "axios";
const API_URL = "https://billing.ourvds.ru";

class SSLPage extends React.Component {
  state = {
    ssl: []
  };
  componentDidMount() {
    const url = `${API_URL}/apiv1/addons/ssl`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ ssl: data });
        console.log(this.state.ssl);
      });
  }

  render() {
    return (
      <div>
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">SSL Сертификаты</h1>
          <p class="lead">Ну что? Пора защититься! ;)</p>
        </div>
        <div class="container">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Название</th>
                  <th scope="col">Цена</th>
                  <th scope="col">Действие</th>
                </tr>
              </thead>
              <tbody>
                {this.state.ssl.map((ss) => (
                  <tr>
                    <th>{ss.name}</th>
                    <td>
                      {ss.cost}
                      {ss.costCurrency}
                    </td>
                    <td>
                      <a
                        class="btn btn-success"
                        href={`https://billing.ourvds.ru/billing.php?do=order&type=shop&item=${ss.id}&one=1`}
                      >
                        Заказать
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Что такое SSL?
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <b>
                  SSL (англ. Secure Sockets Layer — уровень защищённых сокетов)
                </b>
                — криптографический протокол, который подразумевает более
                безопасную связь. Он использует асимметричную криптографию для
                аутентификации ключей обмена, симметричное шифрование для
                сохранения конфиденциальности, коды аутентификации сообщений для
                целостности сообщений.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Для чего мне понадобиться SSL?
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                SSL позволить защитить сайт, определенные форменные зоны,
                например Персональные данные при регистрации пользователя на
                сайте :)
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default SSLPage;
