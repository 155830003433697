import React, { Component } from "react";
import paginate from "paginate-array";
import Search from "../../Search";
const API_URL = "https://billing.ourvds.ru";

class DedicatedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todos: [],
      size: 10,
      page: 1,
      currPage: null
    };

    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    fetch(`${API_URL}/apiv1/dedicated`)
      .then((response) => response.json())
      .then((dedicated) => {
        const { page, size } = this.state;

        const currPage = paginate(dedicated, page, size);

        this.setState({
          ...this.state,
          dedicated,
          currPage
        });
      });
  }

  previousPage() {
    const { currPage, page, size, dedicated } = this.state;

    if (page > 1) {
      const newPage = page - 1;
      const newCurrPage = paginate(dedicated, newPage, size);

      this.setState({
        ...this.state,
        page: newPage,
        currPage: newCurrPage
      });
    }
  }

  nextPage() {
    const { currPage, page, size, dedicated } = this.state;

    if (page < currPage.totalPages) {
      const newPage = page + 1;
      const newCurrPage = paginate(dedicated, newPage, size);
      this.setState({ ...this.state, page: newPage, currPage: newCurrPage });
    }
  }

  handleChange(e) {
    const { value } = e.target;
    const { dedicated, page } = this.state;

    const newSize = +value;
    const newPage = 1;
    const newCurrPage = paginate(dedicated, newPage, newSize);

    this.setState({
      ...this.state,
      size: newSize,
      page: newPage,
      currPage: newCurrPage
    });
  }

  render() {
    const { page, size, currPage } = this.state;

    return (
      <div>
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Выделенные сервера</h1>
          <p class="lead">
            Для маштабных проектов, которые планируют размещать проекты
            Realtime, по максимуму, и без ограничений ;)
          </p>
        </div>
        <div class="container">
          <Search />
          <button class="btn btn-success" onClick={this.previousPage}>
            Назад
          </button>
          <button class="btn btn-success float-right" onClick={this.nextPage}>
            Вперед
          </button>
          <hr />
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Тарифы</th>
                </tr>
              </thead>
              <tbody>
                {currPage && (
                  <div>
                    {currPage.data.map((dedicated) => (
                      <tr>
                        <th>{dedicated.name}</th>
                        <td>{dedicated.description}</td>
                        <td>
                          {dedicated.cost}
                          {dedicated.costCurrency}
                        </td>
                        <td>
                          <a
                            class="btn btn-success"
                            href={`https://billing.ourvds.ru/billing.php?do=order&vid=${dedicated.vid}&tarif=${dedicated.id}`}
                          >
                            Заказать
                          </a>
                        </td>
                      </tr>
                    ))}
                  </div>
                )}
              </tbody>
            </table>
          </div>
          <hr />
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Почему у Вас большое кол-во локаций?
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  Мы стараемся предложить широкий спектр, в плане Географии, под
                  индивидуальные цели, и задачи
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Имеется у Вас панель управления Выделенным сервером?
                  </button>
                </h5>
              </div>

              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div class="card-body">
                  Верно, мы предоставляем возможность управлять сервером, без
                  необходимости обращаться в поддержку Возможности панели:
                  <ul>
                    <li>Управление питанием</li>
                    <li>Просмотр статистики сети, ресурсов</li>
                    <li>KVM-over IP</li>
                    <li>...и многое другое</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTree"
                    aria-expanded="false"
                    aria-controls="collapseTree"
                  >
                    Какую панель управления можете установить?
                  </button>
                </h5>
              </div>
              <div
                id="collapseTree"
                class="collapse"
                aria-labelledby="headingTree"
                data-parent="#accordion"
              >
                <div class="card-body">
                  В составе базового администрирования, согласно
                  предварительному ТЗ, проведем установку рядовых ПО
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    );
  }
}
export default DedicatedPage;
