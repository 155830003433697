import React from "react";
import axios from "axios";
const API_URL = "https://billing.ourvds.ru";

class RootpanelPage extends React.Component {
  state = {
    rootpanel: []
  };
  componentDidMount() {
    const url = `${API_URL}/apiv1/addons/rootpanel`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ rootpanel: data });
        console.log(this.state.rootpanel);
      });
  }

  render() {
    return (
      <div>
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Лицензии RootPanel</h1>
          <p class="lead">
            Ну что? Пора открывать хостинг, и начать зарабатывать! ;)
          </p>
        </div>
        <div class="container">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Название</th>
                  <th scope="col">Описание</th>
                  <th scope="col">Цена в месяц</th>
                  <th scope="col">Цена установки</th>
                  <th scope="col">Действие</th>
                </tr>
              </thead>
              <tbody>
                {this.state.rootpanel.map((rp) => (
                  <tr>
                    <th>{rp.name}</th>
                    <td>{rp.description}</td>
                    <td>
                      {rp.cost}
                      {rp.costCurrency}
                    </td>
                    <td>
                      {rp.cost_setup}
                      {rp.cost_setupCurrency}
                    </td>
                    <td>
                      <a
                        class="btn btn-success"
                        href={`https://billing.ourvds.ru/billing.php?do=order&vid=${rp.vid}&tarif=${rp.id}`}
                      >
                        Заказать
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Дополнение к биллингу
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Модуль</th>
                      <th scope="col">Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Дополнительный домен к Лицензии</th>
                      <td>15.08$/разово</td>
                    </tr>
                    <tr>
                      <th>Дополнительный домен к Лицензии+</th>
                      <td>9.88$/разово</td>
                    </tr>
                    <tr>
                      <th>Поддержка поддоменов</th>
                      <td>15.08$/разово</td>
                    </tr>
                    <tr>
                      <th>Поддержка поддоменов+</th>
                      <td>9.88$/разово</td>
                    </tr>
                    <tr>
                      <th>Система шаблонов</th>
                      <td>20.28$/разово</td>
                    </tr>
                    <tr>
                      <th>Вечный файл Лицензии</th>
                      <td>20.28$/разово</td>
                    </tr>
                    <tr>
                      <th>Скрытый копирайт</th>
                      <td>20.28$/разово</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default RootpanelPage;
