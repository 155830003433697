import React from "react";
import axios from "axios";
const API_URL = "https://billing.ourvds.ru";

class VServerPage extends React.Component {
  state = {
    vserver: []
  };
  componentDidMount() {
    const url = `${API_URL}/apiv1/vps`;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ vserver: data });
        console.log(this.state.vserver);
      });
  }

  render() {
    return (
      <div>
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">VPS/VDS</h1>
          <p class="lead">
            Для больших проектов, которые планируют размещать проекты Realtime,
            по максимуму, и без ограничений ;)
          </p>
        </div>
        <div class="container">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Название</th>
                  <th scope="col">Описание</th>
                  <th scope="col">Цена</th>
                  <th scope="col">Действие</th>
                </tr>
              </thead>
              <tbody>
                {this.state.vserver.map((vs) => (
                  <tr>
                    <th>{vs.name}</th>
                    <td>{vs.description}</td>
                    <td>
                      {vs.cost}
                      {vs.costCurrency}
                    </td>
                    <td>
                      <a
                        class="btn btn-success"
                        href={`https://billing.ourvds.ru/billing.php?do=order&vid=${vs.vid}&tarif=${vs.id}`}
                      >
                        Заказать
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Где размещаются сервера?
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                Наши сервера находятся в Нидерландах, Налдвейк. Дата-Центр
                (GreenHouse)
                <br />
                На локации используется защита от DDOS-атак на уровне сети
                (Layer 4)
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Какая панель управления?
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                Используется панель управления Proxmox VE.
                <br /> С Виртуализацией KVM и LXC (по запросу), все ресурсы
                реальные, согласно ТП
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Что поддерживается на Виртуальном сервере?
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                На Виртуальном сервере, ограничения по ресурсам. Все ПО
                поддерживается Список ОС:
                <ul>
                  <li>Debian</li>
                  <li>CentOS</li>
                  <li>Ubuntu</li>
                  <li>Fedora</li>
                  <li>Suse</li>
                  <li>Windows</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default VServerPage;
