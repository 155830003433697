import React from "react";
import { Link } from "react-router-dom";

class IndexPage extends React.Component {
  render() {
    return (
      <div>
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Добро пожаловать</h1>
          <p class="lead">
            Хостинг-Провайдер с широким спектром к вашим услугам!
          </p>
        </div>

        <div class="container">
          <div class="card-deck mb-3 text-center">
            <div class="card mb-4 shadow-sm">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Виртуальный сервер</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">
                  <small class="text-muted">от</small> 200₽
                </h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>от 1Гб ОЗУ</li>
                  <li>от 10Гб Диска</li>
                  <li>Без ограничений по трафику</li>
                  <li>Средняя скорость 100Mb/s</li>
                </ul>
                <Link
                  to="/price/vserver"
                  class="btn btn-lg btn-block btn-primary"
                >
                  Подобрать тариф
                </Link>
              </div>
            </div>
            <div class="card mb-4 shadow-sm">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Выделенный сервер</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">
                  <small class="text-muted">от</small> 2000₽
                </h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>от 8Гб ОЗУ</li>
                  <li>от 120Гб Диски</li>
                  <li>Множество локаций</li>
                  <li>Порты от 100Mb/s</li>
                </ul>
                <Link
                  to="/price/dedicated"
                  class="btn btn-lg btn-block btn-primary"
                >
                  Подобрать тариф
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndexPage;
