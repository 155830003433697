import React from "react";

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <div className="container text-center">
          <h4>Ошибка 404</h4>
          <p>Данная страница недоступна, либо была изменена или удалена.</p>
          <a href="/" class="btn btn-primary btn-round">
            Вернутся на главную
          </a>
        </div>
        <br />
      </div>
    );
  }
}
export default NotFound;
